import ReactDOM from "react-dom";

const Modal = props => {
	if (typeof window === "undefined") {
		return null;
	}
	const portalHolder = document.createElement("div");
	portalHolder.id = "portal";
	document.body.appendChild(portalHolder);
	return ReactDOM.createPortal(props.children, document.getElementById("portal"));
};

export default Modal;
