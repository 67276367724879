import gql from "graphql-tag";

export const M2_PRICE = gql`
	query M2Price($postnr: String) {
		costEnebolig: apartmentCost(postnr: $postnr, boligtype: "Enebolig") {
			document {
				Nybolig
				Boligverdi_median_prom
			}
		}
		costRekkehus: apartmentCost(postnr: $postnr, boligtype: "Småhus") {
			document {
				Nybolig
				Boligverdi_median_prom
			}
		}
		costLeilighet: apartmentCost(postnr: $postnr, boligtype: "Leilighet") {
			document {
				Nybolig
				Boligverdi_median_prom
			}
		}
	}
`;
