import styled, { createGlobalStyle } from 'styled-components';

export const Verdivurdering = styled.div`
  margin: 1em 0 4em;
  /*button {
    display: block;
    width: 210px;
    height: 48px;
    padding: 0.6em;
    margin: 5px auto;
    background-color: ${({ theme }) => theme.buttons.primary.background};
    text-decoration: none;
    color: #242525;
    border: none;
    cursor: pointer;
    &:hover {
      background-color: #242525;
      color: #e8c893;
    }
  }*/
`;

export const GlobalStyles = createGlobalStyle`

.kampanjeside{
  font-size: 16px;
  color: #fff;
  @media(min-width: 600px) {
    font-size: 16px;
  }
}

.search, .search-params {
  input[type="text"],
  input[type="tel"] {
    font-family: "Proxima-Nova", sans-serif;
    border: none;
    background-color: transparent;
    font-size: 1.1rem;
    font-weight: 100;
    color: #fff;
    flex: 1;
    flex-grow: 4;
    border-bottom: 1px solid #E9CA90;
    width: 0;
    margin: 0 10px;
    min-width: 70px;
    border-radius: 0;
    &:focus {
      box-shadow: none;
      outline: none;
    }
    &::placeholder {
      color: #afafaf;
      opacity: 1;
      text-align: left;
    }
  }
  input[type="tel"] {
    flex-grow: 1;
    text-align: center;
    
  }
}

.search-params {
    background-color: #1d1d1d;
    padding: 1.2em 0.5em 2em;
    display: flex;
    justify-content: space-between;
    text-align: center;
  
    label {
      font-size: 1em;
      font-weight: 100;
    }
    input {
      font-family: Proxima-Nova;
    }
    input[type="text"],
    input[type="tel"] {
      padding: 0.2em 0;
      font-size: 1.6em;
      font-weight: 300;
      text-align: center;
      display: block;
      min-width: auto;
      width: 30px;
      &::placeholder {
        color: #424242;
        opacity: 1;
        text-align: center;
      }
      
      @media(min-width: 600px) {
        font-size: 2.6em;
        width: 75px;
      }
    }
  }

  .search {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0em;
    background-color: #17181a;
    input[type="text"] {
      font-weight: 500;
      font-size: 1.4em;
      border: none;
    }
  }

  .elementListWrap {
    background-color: #1d1d1d;
    padding: 0.6em 1em;
  }
  .element {
    margin: 0px 0 0px;
    padding: 0.6em 0;
    font-size: 1em;
    cursor: pointer;
    text-align: left;
  }
  
  .apartments {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 80%;
    margin: 0 auto;
  
    h1 {
      width: 100%;
    }
  }
  .enhet {
    background-color: #141414;
    font-weight: 100;
    padding: 0.5em 1.2em 0.5em;
    font-size: 1em;
    cursor: pointer;
    text-align: left;
    &:first-child{
      padding-top: 0.8em;
    }
  
    .enhet-info {
      display: flex;
      justify-content: space-between;
      font-size: 1em;
      padding: 0.5em 0 1.4em;
      border-bottom: 1px solid #3e3e3e;
      color: #aaa;
    }
  }
`;
