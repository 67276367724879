import React from 'react';
import styled from 'styled-components';
import debounce from 'lodash.debounce';
import { withRouter, Route, Link } from 'react-router-dom';
import BarLoader from 'react-spinners/BarLoader';
import Enhet from './enhet';
import { getApartments } from './apartment-gql';
import { Address } from './address-gql';
import { capitalize, onEnter } from './utils';

class SearchApartment extends React.Component<any, any> {
  private debouncedSearch: any;
  private debouncedUpdateSearch: any;
  private setInputValueWithSearch: any;
  private setInputValueWithUpdateSearch: any;
  public state: any;

  constructor(props: any) {
    super(props);

    this.state = {
      isLoading: false,
      gatenavn: props.gatenavn || '',
      etasje: '',
      nummer: '',
      bokstav: '',
      adresseId: '',
      apartments: null
    };

    this.debouncedSearch = debounce(this.search, 500);
    this.debouncedUpdateSearch = debounce(this.updateSearch, 500);
    this.setInputValueWithSearch = this.setInputValue.bind(this, 1);
    this.setInputValueWithUpdateSearch = this.setInputValue.bind(this, 2);
    this.goToApartment = this.goToApartment.bind(this);
    this.setAddress = this.setAddress.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
  }

  setInputValue(searchOption: any, e: any) {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        if (searchOption === 1) {
          this.debouncedSearch();
        }
        if (searchOption === 2) {
          this.debouncedUpdateSearch();
        }
      }
    );
  }

  setAddress(e: any) {
    const adresseId = e.target.getAttribute('data-id');
    this.setState({ isLoading: true });
    this.props.client
      .query({
        query: getApartments,
        variables: {
          AdresseId: adresseId
        }
      })
      .then((result: any) => {
        const leil =
          (result.data.apartments && result.data.apartments[0].document) || {};
        this.setState({
          isLoading: false,
          adresseId,
          apartments: result.data.apartments,
          nummer: leil.HusNr && leil.HusNr.toString(),
          gatenavn: leil.Gatenavn,
          bokstav: leil.Oppgang,
          etasje: leil && leil.EtasjeNr && leil.EtasjeNr.toString(),
          addresses: null
        });
      });
  }

  updateSearch() {
    const { adresseId, nummer, bokstav, etasje, gatenavn }: any = this.state;
    const variables: any = {
      HusNr: nummer,
      EtasjeNr: etasje,
      Oppgang: bokstav
    };
    if (gatenavn) {
      variables.Gatenavn = gatenavn;
    } else {
      variables.AdresseId = adresseId;
    }

    this.setState({ isLoading: true });

    this.props.client
      .query({
        query: getApartments,
        variables
      })
      .then((result: any) => {
        if (!result.data.apartments[0]) {
          this.setState((state: any) => {
            return { ...state, apartments: [] };
          });
          this.setState({ isLoading: false });
          return;
        }
        const leil = result.data.apartments[0].document;
        this.setState((state: any) => {
          return {
            ...state,
            isLoading: false,
            adresseId,
            apartments: result.data.apartments,
            gatenavn: leil.Gatenavn,
            nummer,
            bokstav,
            etasje
          };
        });
      });
  }

  search() {
    // const nummer = this.state.nummer;
    const gatenavn = this.state.gatenavn.length > 0 ? this.state.gatenavn : '';

    if (!gatenavn) {
      return false;
    }
    this.setState({
      apartments: null
    });
    this.setState({ isLoading: true });

    this.props.client
      .query({
        query: Address,
        variables: {
          address: gatenavn
        }
      })
      .then((res: any) => {
        this.setState({
          isLoading: false,
          addresses: res.data.address
        });
      });
    return true;
  }

  goToApartment(e: any) {
    this.props.history.push(
      `/verdivurdering-boligkalkulator/apartment/${e.currentTarget.getAttribute('data-id')}`
    );
  }
  render() {
    let sortableApartments = null;
    if (this.state.apartments && this.state.apartments.length > 0) {
      sortableApartments = [...this.state.apartments];
    }
    return (
      <>
        <Route
          exact
          path="/endre"
          render={() => {
            return (
              <>
                <TopText />
                <SectionTitle className="section-title">Endre søk</SectionTitle>
              </>
            );
          }}
        />
        <Route
          exact
          path="/"
          render={() => {
            return (
              <div id="om">
                <TopText>
                  Tjenesten som gjør eiendomsjakten enklere for deg
                </TopText>
                <SectionTitle className="section-title">
                  <p>
                    Som medlem av Nybygg Notis får du fortrinnsrett på nybygg
                    før de slippes til resten av markedet. Med dette blir det
                    enklere å være tidlig ute og holde seg oppdatert på
                    prosjekter i områder man holder øye med.
                  </p>

                  <p>
                    I tillegg til eksklusiv informasjon om kommende prosjekter,
                    vil du også få en estimert verdivurdering av din nåværende
                    eiendom, samt mulighet til å følge prisutviklingen måned for
                    måned.
                  </p>
                  <div />
                  {/* hr */}
                  <p>
                    Sjekk ut kommende prosjekter og registrer deg{' '}
                    <Link to="/registrer">her</Link>, eller start eiendomsjakten
                    i søkefeltet under ved å få et prisestimat på din nåværende
                    eiendom.
                  </p>
                </SectionTitle>
              </div>
            );
          }}
        />

        <div className="search">
          <MainInput
            type="text"
            placeholder="Søk adresse"
            name="gatenavn"
            autoComplete="off"
            value={this.state.gatenavn.toLowerCase()}
            onChange={this.setInputValueWithSearch}
          />
        </div>
        {sortableApartments && (
          <>
            <div className="search-params">
              <label>
                NUMMER
                <input
                  type="tel"
                  name="nummer"
                  placeholder="2"
                  value={this.state.nummer || ''}
                  onChange={this.setInputValueWithUpdateSearch}
                />
              </label>

              <label>
                BOKSTAV
                <input
                  type="text"
                  name="bokstav"
                  placeholder="A"
                  value={this.state.bokstav || ''}
                  onChange={this.setInputValueWithUpdateSearch}
                />
              </label>
              <label>
                ETASJE
                <input
                  type="tel"
                  name="etasje"
                  placeholder="1"
                  value={this.state.etasje || ''}
                  onChange={this.setInputValueWithUpdateSearch}
                />
              </label>
            </div>
            <div>
              {sortableApartments.length > 0
                ? sortableApartments
                    .sort((a: any, b: any) => {
                      if (a?.document?.BruksenhetNr) {
                        return a.document.BruksenhetNr.localeCompare(
                          b.document.BruksenhetNr
                        );
                      }
                      return 0;
                    })
                    .map((apartment: any, i: number) => {
                      return (
                        <Enhet
                          key={`apartment_${i}`}
                          goToApartment={this.goToApartment}
                          apartment={apartment.document}
                        />
                      );
                    })
                : null}
            </div>
          </>
        )}
        <>
          <BarLoader
            height="2px"
            width="100%"
            loading={this.state.isLoading}
            color="#E9CA90"
          />

          {this.state.addresses && this.state.addresses.length > 0 && (
            <div className="elements">
              <div className="elementListWrap">
                {this.state.addresses &&
                  this.state.addresses.map((address: any) => {
                    return (
                      <div
                        key={address.addressId}
                        data-id={address.addressId}
                        className="element"
                        tabIndex={0}
                        onKeyDown={onEnter(this.setAddress)}
                        onClick={this.setAddress}
                      >
                        {capitalize(address.street.toLowerCase())}{' '}
                        {address.number}
                        {address.letter && ` ${address.letter}`},{' '}
                        {capitalize(address.postalName.toLowerCase())}
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </>
      </>
    );
  }
}

const MainInput = styled.input`
  padding: 15px !important;
  -webkit-appearance: none;
  font-size: 16px !important;
  margin: 0 !important;
  background: rgb(75, 75, 75) !important;
  appearance: none;
`;

const TopText = styled.h1`
  font-size: 2em;
  margin-top: 3em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  font-family: 'DomaineDisp', serif;
  text-align: center;
`;

const SectionTitle = styled.div`
  text-align: left;
  line-height: 1.4;
  font-weight: 300;
  font-size: 1em;
  margin: 0.3em 0 1em;
  text-align: center;
  &.grey {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 6em 2em;
  }
`;

export default withRouter(SearchApartment);
