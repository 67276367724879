import gql from "graphql-tag";

export const getApartments = gql`
	query Apartments($AdresseId: String, $HusNr: String, $EtasjeNr: String, $Oppgang: String, $Gatenavn: String) {
		apartments(AdresseId: $AdresseId, HusNr: $HusNr, EtasjeNr: $EtasjeNr, Oppgang: $Oppgang, Gatenavn: $Gatenavn) {
			document {
				id
				AlternativAdresse
				Gatenavn
				Verdi
				VerdiLav
				VerdiHoy
				EtasjeNr
				HusNr
				Oppgang
				KommuneNavn
				Bruksareal
				BruksenhetNr
			}
		}
	}
`;

export const GET_APARTMENT = gql`
	query Apartment($apartmentId: Int) {
		apartment(apartmentId: $apartmentId) {
			id
			AlternativAdresse
			Gatenavn
			Verdi
			Verdi30
			Verdi60
			Verdi90
			VerdiLav
			VerdiLav30
			VerdiLav60
			VerdiLav90
			VerdiHoy
			VerdiHoy30
			VerdiHoy60
			VerdiHoy90
			EtasjeNr
			HusNr
			Oppgang
			KommuneNavn
			Bruksareal
			EstimertBRA
			EstimertPROM
			BruksenhetNr
			PostNr
			PostSted
			BoligTypeNavn
			PrisKvalitet
			PrisKvalitet30
			PrisKvalitet60
			PrisKvalitet90
			FylkeNavn
		}
	}
`;

export const GET_SAVED_APARTMENT = gql`
	query GetSavedApartment {
		getSavedApartment {
			apartmentId
		}
	}
`;

export const GET_POSTNR_LOCATION = gql`
	query GetPostnrLocation($postnr: String) {
		getPostnrLocation(postnr: $postnr) {
			lat
			lng
		}
	}
`;

export const generateSearchInput = (querystring, exclusive, priceRange, geolocation) => {
	return {
		input: {
			query: querystring,
			filter: {
				property: {
					saleType: "NYBYGG",
					exclusive,
					geolocation,
					sort: "DISTANCEASC",
					sold: false,
					available: true,
					price: {
						from: priceRange.min,
						to: priceRange.max
					}
				}
			},
			searchIn: {
				type: "property",
				pagination: {
					limit: 4,
					page: 1
				}
			}
		}
	};
};

export const allExclusiveInput = querystring => {
	return {
		input: {
			query: querystring,
			filter: {
				property: {
					saleType: "NYBYGG",
					exclusive: true,
					sold: false,
					available: true
				}
			},
			searchIn: {
				type: "property",
				pagination: {
					limit: 4,
					page: 1
				}
			}
		}
	};
};

export const GET_EKSKLUSIV_APARTMENTS = gql`
	query Search($input: SearchInput) {
		search(input: $input) {
			result {
				properties {
					list {
						id
						address
						place
						location {
							municipality
							municipalityArea
							county
						}
						type
						freetext {
							list {
								finnLocalArea
							}
						}
						price {
							info {
								price
								priceTo
							}
						}
						area {
							bra
							braTo
						}
						images {
							first {
								url
							}
						}
					}
				}
			}
		}
	}
`;
