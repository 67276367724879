import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import fetch from 'isomorphic-unfetch';

const httpLink = createHttpLink({
  headers: {
    'x-client-id': 'settprispaa',
  },
  uri: 'https://4cgproxy.reeltime.no/',
  fetch,
});

const clientFactory = () => {
  return new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
  });
};

export default clientFactory;
