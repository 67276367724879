/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import { GET_APARTMENT } from './apartment-gql';
import Loading from './loader';
import ApartmentInfo from './apartment-info';
import Modal from './modal';
import fetch from 'isomorphic-unfetch';
import { RangeContext } from '.';
import { FormRow } from '../../ui/form/Form';
import { getDiggerData } from '../../../utils/tracking/digger';
import { SiteContext } from '../../../utils/context/SiteContext';
import { useForm } from '../../../utils/hooks/useForm';
import { createGTMEvent } from '../../../utils/tracking/gtm';
import {
  createLeadMutation,
  SAVE_DIGGER_ID_MUTATION
} from '../../../../client/__graphql__/mutations';
import {
  ILeadInput,
  ILeadResponse,
  ISaveDiggerInput,
  ISaveDiggerResponse
} from '../../../__types__/global';
import { Input } from '../../ui/form/Input';
import { ButtonGroup } from '../../ui/button/ButtonGroup';
import { Button } from '../../ui/button/Button';
import { ButtonLink } from '../../ui/link/ButtonLink';
import { DiggerType, useDiggerId } from '../../../utils/hooks/useDiggerId';

const Apartment = (props: any) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [source, setSource] = useState('settprispaa');
  const rangeContext = useContext(RangeContext);
  const { routeConfig } = useContext(SiteContext);
  const { submitWithDigger } = useDiggerId();

  const range = rangeContext.range;

  useEffect(() => {
    if (props.history.location.search.indexOf('recheck=it') > -1) {
      window.sessionStorage.recheckit = true;
      setSource('settprispaa-recheckit');
    }
  }, []);

  const image = 'https://cdn.reeltime.no/pm_assets/img/bilde_pmverdi2.jpg';

  const VALUATION_MUTATION = createLeadMutation({
    valuation: {
      response: `{
        success
        data {
          id
          address
          zip
          phone
          email
        }
      }`
    }
  });

  const [lead] = useMutation<ILeadResponse, ILeadInput>(VALUATION_MUTATION, {
    onCompleted: ({ lead }) => {
      setLoading(false);
      if (lead.valuation?.success) {
        window.scroll(0, 0);
        setFormSubmitted(true);
        // GTM tracking

        createGTMEvent({
          event: 'pmFormSubmission',
          gaEvent: 'Verdivurdering NY',
          gaCategory: 'Form',
          gaAction: 'Submit'
        });
      }
    }
  });

  const { fields, getFields, handleSubmit, handleChange } = useForm({
    fields: [
      {
        type: 'text',
        name: 'address',
        placeholder: 'Eks. Adresseveien 23',
        value: '',
        label: 'Adresse'
      },
      {
        type: 'text',
        name: 'zip',
        placeholder: 'Eks. 1234',
        value: '',
        label: 'Postnummer *',
        required: true
      },
      {
        type: 'tel',
        name: 'phone',
        placeholder: 'Eks. 00000000',
        value: '',
        label: 'Telefon *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'email',
        name: 'email',
        placeholder: 'Eks. ole@mail.no',
        value: '',
        label: 'E-post *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      }
    ],
    submit: () => {
      const preparedFields: any = getFields(fields);

      // kilde - settprispå eller settprispå-recheckit
      submitWithDigger(
        DiggerType.VERDIVURDERING,
        {
          zip: preparedFields.zip
        },
        (data) => {
          lead({
            variables: {
              input: {
                ...preparedFields,
                diggerId: data.diggerId,
                referer: `${location.pathname}${
                  source === 'settprispaa-recheckit'
                    ? '?recheck=it'
                    : location.search
                }`,
                leadType: 'VERDIVURDERING',
                pageType: 'GENERELL'
              }
            }
          });
        }
      );
    }
  });

  const close = (e: any) => {
    e.preventDefault();
    setFormSubmitted(false);
  };

  const {
    loading: loadingApart,
    error,
    data
  } = useQuery(GET_APARTMENT, {
    fetchPolicy: 'network-only',
    client: props.client,
    variables: { apartmentId: parseInt(props.match.params.id, 10) }
  });
  if (error) {
    console.log('error: ', error);
  }
  if (loadingApart) {
    return <Loading />;
  }

  return (
    <>
      <AnotherPointlessContainer bg={image} range={range}>
        <div className="gradient"></div>
        <ThatOtherWrapperContainer>
          {formSubmitted && (
            <Modal>
              <BG>
                <Takk>
                  Takk for din henvendelse<Close onClick={close}>Lukk</Close>
                </Takk>
              </BG>
            </Modal>
          )}
          <ApartmentInfo client={props.client} data={data} />
        </ThatOtherWrapperContainer>
      </AnotherPointlessContainer>
      <ThatOtherWrapperContainer>
        <Form className="settprispaa-form" onSubmit={handleSubmit}>
          <SubmitMessage>
            Send inn skjemaet, så vil en av våre meglere kontakte deg
          </SubmitMessage>
          {fields.map((item: any) => {
            return (
              <FormRow key={`field_${item.name}`}>
                <Input
                  type={item.type}
                  name={item.name}
                  label={item.label}
                  labelAfter={item.labelAfter}
                  placeholder={item.placeholder}
                  value={item.value}
                  onChange={handleChange}
                  error={item.error}
                />
              </FormRow>
            );
          })}
          <ButtonGroup>
            <Button
              type="primary"
              colorTheme="gold"
              disabled={loading}
              loading={loading}
            >
              Kontakt meg
            </Button>
            <ButtonLink
              as="a"
              href={routeConfig.personvern.url}
              className="privacy-link-mobile"
            >
              Personvernpolicy
            </ButtonLink>
          </ButtonGroup>
        </Form>
      </ThatOtherWrapperContainer>
    </>
  );
};

const AnotherPointlessContainer = styled.div<{ bg: string; range: any }>`
  position: relative;

  background-position: ${(props) =>
    props.range === 1 ? 'center -100px' : 'center center'};
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  margin: 0 auto;
  padding: 4em 0 0;

  .gradient {
    position: absolute;
    display: none;
    width: 100%;
    height: 500px;
    bottom: 0;
    background: ${({ theme }) =>
      `linear-gradient(to top, ${theme.colors.body}, transparent)`};
  }

  @media (min-width: 600px) {
    background-image: url(${(props) => props.bg});
  }

  @media (min-width: 1200px) {
    background-position: 0 -300px;
    .gradient {
      bottom: 300px;
    }
  }
`;

const ThatOtherWrapperContainer = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 2em 1em;
`;

const Label = styled.label`
  span {
    color: rgb(100, 100, 100);
  }
`;

const Close = styled.div`
  background-color: #333;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  width: 100px;
  text-align: center;
  font-size: 1rem;
  left: 0;
  right: 0;
  margin: 1em auto;
  padding: 0.4em;
  max-width: 100px;
  cursor: pointer;
`;

const BG = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
`;
const Takk = styled.div`
  font-size: 2em;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100px;
`;

const Form = styled.form`
  margin: 3em 0 0;
`;

const SubmitMessage = styled.div`
  text-align: center;
  margin: 0 0 1em;
`;

/*
const Input = styled.input`
  color: rgb(255, 255, 255);
  background: rgb(24, 24, 25);
  border: none;
  padding: 18px;
  font-size: 16px;
  width: 100%;
  height: 53px;
  margin: 0 0 10px;
  padding: 0 15px;
  -webkit-appearance: none;
  appearance: none;

  &::placeholder {
    color: rgb(100, 100, 100);
  }
  &.invalid {
    border: 1px solid red;
  }

  &.form-* {
    font-weight: 100;
    font-size: 0.8em;
  }
  &.form-input-send {
    background-color: #e8c893;
    color: #242525;
    margin: 10px 0 0;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      background-color: #242525;
      color: #e8c893;
    }
  }
`;
*/

export default Apartment;
