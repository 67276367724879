import gql from "graphql-tag";

export const Address = gql`
	query Address($address: String) {
		address(address: $address) {
			addressId
			street
			number
			letter
			postal
			postalName
		}
	}
`;
